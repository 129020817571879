@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

/* BASE CSS VALUES */
html,
body,
div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
code,
del,
dfn,
em,
img,
q,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
}

.edit-site-dropdown {
  background-color: #548c3f;
}

span.mat-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: row-reverse;
}

ol,
ul {
  margin: 16px 0px;
  padding: 0px 0px 0px 40px;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* -------- */

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  background-color: #f5f5f5;
  height: 100%;
  margin: 0 auto;
  padding: 0;
}

main.main-content {
  width: 66%;
  margin: 0 auto;
  padding-top: 1em;
}

a,
a:visited {
  color: #6991cb;
  text-decoration: underline;
}

a:hover {
  color: #125699;
}
.mat-expansion-indicator::after {
  height: 1px;
  width: px;
  border-style: solid !important;
  border-width: 0 3px 3px 0 !important;
  display: flex !important;
  flex-flow: column !important;
  align-items: center !important;
  border-color: #000 !important;
}

.brix-success {
  background-color: #548c3f;

  &:hover {
    background-color: #609c4f;
    cursor: pointer;
  }
}

.brix-error {
  background-color: #e03157;

  &:hover {
    background-color: #f03867;
  }
}

.brix-secondary {
  background-color: #6991cb;

  &:hover {
    background-color: #7599db;
  }

  &:disabled {
    background-color: #cad2de;
  }
}

.brix-primary {
  background-color: #548c3f;

  &:hover {
    background-color: #609c4f;
  }
}

.ql-container {
  min-width: 100%;
}

.ql-code-block-container {
  min-width: 400px;
  display: inline-block;
}

.ql-editor {
  letter-spacing: normal !important;
  word-spacing: 0.1rem !important;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    font-family: 'Open Sans' !important;
  }
  h1 {
    font-size: 2.5rem !important;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }
  h2 {
    font-size: 2rem !important;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  ul,
  ol {
    margin: 1rem 0 !important;
    font-size: 1rem !important;
  }
  .ql-size-small {
    font-size: 0.8rem !important;
  }
  p {
    font-size: 1rem !important;
  }
  li:before {
    display: none;
  }
  li[data-list='bullet'] > .ql-ui:before {
    content: '•';
    font-size: 1.1rem;
  }
  .ql-ui {
    display: inline-flex;
  }

  li[data-list='ordered'] > .ql-ui:before,
  li[data-list='bullet'] > .ql-ui:before {
    vertical-align: sub;
    text-align: center;
  }
  table td {
    vertical-align: top !important;
  }
}
